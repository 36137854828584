import Link from 'next/link';
import Slider from './Slider';

import CostSaving from './assets/cost-saving.svg';
import Incidents from './assets/incidents.svg';
import Performance from './assets/performance.svg';

const BuildFasterSection = () => {
  return (
    <div className="container mx-auto flex w-full flex-col items-center gap-12 py-12 px-4 md:px-8 lg:gap-20 lg:py-32">
      <p className="lg:text-4.5xl max-w-[799px] text-center text-3xl font-semibold text-gray-900 lg:leading-[3.5rem]">
        Build faster and more reliable apps with Polar Signals Cloud
      </p>

      <div className="flex w-full flex-col items-center justify-center gap-12 lg:flex-row">
        <div className="w-auto">
          <div className="relative w-full">
            <Slider />
          </div>
        </div>

        <div className="flex w-full flex-col gap-12 lg:w-2/5 lg:items-center lg:gap-16">
          <div>
            <Incidents />
            <p className="lg:text-1xl my-3 text-lg font-medium text-gray-900">
              Understand Incidents
            </p>
            <p className="mb-1 font-sans text-sm font-light text-gray-700 lg:max-w-[408px] lg:text-base">
              Travel back in time to pinpoint incidents and issues. Profiling data provides unique
              insight and depth into what a process executed over time.
            </p>
            <Link href="/use-cases/understand-incidents">
              <a className="text-brand-dark-blue text-sm ">Learn more</a>
            </Link>
          </div>
          <div>
            <Performance />
            <p className="lg:text-1xl my-3 text-lg font-medium text-gray-900">
              Optimize Performance
            </p>
            <p className="mb-1 font-sans text-sm font-light	text-gray-700 lg:max-w-[408px] lg:text-base">
              Utilize profiling data collected over time to confidently and statistically identify
              hot paths for optimization.
            </p>
            <Link href="/use-cases/optimize-performance">
              <a className="text-brand-dark-blue text-sm ">Learn more</a>
            </Link>
          </div>
          <div>
            <CostSaving />
            <p className="lg:text-1xl my-3 text-lg font-medium text-gray-900">
              Save Infrastructure Costs
            </p>
            <p className="mb-1 font-sans text-sm font-light text-gray-700 lg:max-w-[408px] lg:text-base">
              Many organizations have 20-30% of resources wasted with code paths that could be
              easily optimized.
            </p>
            <Link href="/use-cases/save-infrastructure-costs">
              <a className="text-brand-dark-blue text-sm ">Learn more</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildFasterSection;
